


























import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useWarehouse } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@interface/http.interface";
import { WarehouseResponseDto } from "@interface/warehouse";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SelectWarehouse extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: String, default: "" })
  branchId!: string;

  options: Option<WarehouseResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  @Watch("branchId")
  onChangeBranch(): void {
    const params = new RequestQueryParams();
    params.search = this.buildQuery();
    this.fetchOptions(params);
  }

  fetchOptions(
    params: RequestQueryParamsModel = new RequestQueryParams()
  ): void {
    const { findAll, toOptions } = useWarehouse();
    this.loading = true;
    findAll(params)
      .then(({ data }) => {
        this.options = toOptions(data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { searchBy } = useWarehouse();
    const builder = new SearchBuilder();
    const queries: Array<string> = [];
    const params = new RequestQueryParams();
    if (this.branchId) queries.push(this.buildQuery());
    if (val) queries.push(searchBy({ name: val }));
    params.search = queries.join(builder.AND);

    this.fetchOptions(params);
  }

  findOption(value: string): Option<WarehouseResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }

  buildQuery(): string {
    const builder = new SearchBuilder();
    if (this.branchId) {
      return builder.push(["branchWarehouse.secureId", this.branchId]).build();
    }

    return "";
  }
}
