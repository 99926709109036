var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: _vm.title, visible: _vm.visible },
      on: { cancel: _vm.handleClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleClose } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
                  ]),
                  _vm.$can("update", "warehouse-location")
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading },
                          on: { click: _vm.validateForm }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            rules: _vm.formRules,
            model: _vm.form,
            "wrapper-col": { span: 12 },
            "label-col": { span: 6 }
          }
        },
        [
          _c(
            "a-form-model-item",
            {
              attrs: { label: _vm.$t("lbl_warehouse"), prop: "warehouseName" }
            },
            [
              _c("SelectWarehouse", {
                attrs: { "data-testId": "warehouse_name" },
                on: { "update:meta": _vm.onChangeWarehouse },
                model: {
                  value: _vm.form.warehouseName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "warehouseName", $$v)
                  },
                  expression: "form.warehouseName"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_name"), prop: "name" } },
            [
              _c("a-input", {
                attrs: { "allow-clear": "", "data-testId": "rack_name" },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }